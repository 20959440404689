import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Header from './header';
import Footer from './footer';

export default function Layout({ children }) {
  const { pathname } = useRouter();
  const [dynamicClass, setDynamicClass] = useState('home');

  useEffect(() => {
    if (pathname) {
      const secondUri = pathname.split('/')[1];
      if (secondUri) {
        setDynamicClass(secondUri);
      } else {
        setDynamicClass('home');
      }
    }

  }, [pathname]);

  return (
    <div className={`${dynamicClass}-pageName`}>
      <Header />
      {children}
      <Footer />
    </div>
  );
}