import { Container, Row, Col, Accordion, Button } from "react-bootstrap";
import { useState } from "react";

const Faq = () => {
//const imagePerRow = 4;
  const [next, setNext] = useState(false);

  // const handleMoreImage = () => {
  //   setNext(next + imagePerRow);
  // };

  return (
    <section className="faq-section">
      <Container>
        <Row>
          <Col lg={10} className="m-auto">
            <div className="section-title">
              <h3 className="title">FAQ’s</h3>
              <p>Get Answers to your Questions.Learn and Discover Energy Healing Benefits, Sessions, Techniques, and more. Clear your Doubts and make an Informed Choice. Explore Energy Healing with our FAQs.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={10} className="m-auto">
            <Accordion defaultActiveKey="0" className="faq-accordion" id="accordionExample">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span>What is energy healing?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p> Energy healing is a holistic approach that utilizes various techniques to work with the body&apos;s energy systems, promoting balance, well-being, and self-healing. It involves the practitioner directing or manipulating energy to support the body&apos;s natural healing processes.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <span>How does Zenkify work?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Zenkify connects individuals seeking energy healing with a network of qualified practitioners and resources. It provides a user-friendly interface where you can browse profiles, book sessions, and access online resources such as articles, videos, and guided meditations related to energy healing.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <span>What types of energy healing modalities are available on the platform?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Zenkify offers a diverse range of modalities, including Reiki, Healing Touch, Theta Healing, and more. Each modality has its unique approach and benefits, allowing you to choose the one that resonates with you.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <span>How do I find a suitable energy healing practitioner?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>The platform provides detailed profiles of practitioners, including their qualifications, experience, areas of expertise, and user reviews. You can search based on location, modality, or specific needs to find a practitioner that aligns with your preferences.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <span>Can I receive energy healing remotely?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Yes, many practitioners on the platform offer remote or distance healing sessions. Energy healing can be just as effective when performed remotely, as energy transcends physical boundaries.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <span>Is energy healing suitable for everyone?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Energy healing is generally safe and suitable for most individuals. However, it is essential to consult with a healthcare professional if you have any specific medical concerns or conditions. Energy healing can complement traditional medical treatment but should not replace professional medical advice or treatment.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <span>How many energy healing sessions do I need?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>The number of sessions needed varies depending on individual circumstances and goals. Some individuals may experience immediate benefits after one session, while others may require multiple sessions for more profound and sustained healing. Your practitioner can guide you on the recommended session frequency and duration based on your unique needs.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <span>Can energy healing be used alongside other healing modalities or treatments?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Yes, energy healing can complement other healing modalities or treatments. It is important to communicate with your healthcare providers and inform them about any energy healing practices you are incorporating into your wellness journey.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <span>Is energy healing suitable for children and animals?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Yes, energy healing can be beneficial for children and animals. Many practitioners specialize in working with children or offer specific techniques for animal healing. Always ensure the practitioner has experience in working with the specific age group or species.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <span>What are the costs associated with energy healing sessions?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Pricing varies depending on the practitioner and modality. You can find detailed pricing information on each practitioner&apos;s profile. Energy Healers may also offer packages and discounts.</p>
                </Accordion.Body>
              </Accordion.Item>
              {next == false?
              <div className="text-center ">
              <Button
              className="theme-btn text-align-center my-4"
              onClick={()=>setNext(true)}
              >
              View more
              </Button>
              </div>:
              <>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  <span>Is my personal information secure on the platform?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Yes, we prioritize data security and privacy. Our platform is designed to protect your personal information, and we adhere to strict privacy policies.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  <span>What is your cancellation and refund policy?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Our cancellation and refund policies may vary among practitioners. You have to reach out to the concerned Energy Healer for cancellation and refund. After receiving the approval from the practitioner, Zenkify will refund the amount after deducting the processing fee charged by Stripe. </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  <span>How can I contact customer support?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>You can reach our customer support team by emailing hello@zenkify.com</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  <span>Is this platform available internationally?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Yes, our platform is accessible to users worldwide. We have practitioners from USA for now, providing services to a global clientele.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header>
                  <span>Can I become a practitioner on Zenkify?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>If you are a qualified energy healing practitioner interested in joining our platform, please visit our “Join As A Healer” page for more information on the application process.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header>
                  <span>How is my payment information secured on your platform?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>We prioritize the security of your payment information. We use Stripe, a trusted and industry-leading payment processing platform, to handle all payment transactions. Stripe employs advanced encryption and security measures to ensure that your payment data is kept safe and confidential.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>
                  <span>What payment methods can I use on your platform through Stripe?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>Stripe supports a wide range of payment methods, including major credit cards (Visa, MasterCard, American Express), debit cards, and various digital wallets. You can choose the payment method that is most convenient for you.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header>
                  <span>Do you store my credit card information on your platform?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>No, we do not store your credit card information on our platform. We follow best practices for security, and all payment data is securely managed by Stripe. This ensures that your sensitive financial information remains protected.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18">
                <Accordion.Header>
                  <span>How can I provide feedback or a review after my energy healing session?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>We encourage clients to share their feedback and experiences by leaving reviews for the practitioners they have worked with. After your session, you will receive an email with instructions on how to leave a review. Your feedback is valuable not only to the practitioner but also to the community as it helps others make informed decisions when choosing a practitioner. Your reviews contribute to the quality of our platform and the services offered.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="19">
                <Accordion.Header>
                  <span>Can I use insurance to cover the cost of energy healing sessions?</span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>In most cases, energy healing sessions are not covered by insurance because they are considered complementary or alternative therapies. However, it&apos;s a good idea to check with your insurance provider as some policies may offer coverage for specific holistic therapies. We recommend verifying your coverage with your insurance company directly.</p>
                </Accordion.Body>
              </Accordion.Item></>}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Faq