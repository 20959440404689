import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import dynamic from "next/dynamic";
import Image from "next/image";
import styles from "@/styles/Testimonial.module.css";
const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
  ssr: false,
});

const Testimonial = ({ props }) => {
  const [testimonials, setTestimonial] = useState(props?.testimonials ?? []);

  return (
    <div>
      <section className={styles["testimonial-section"]}>
        <Container>
          <Row>
            <Col lg={5} className="align-self-center pe-lg-5">
              <div className={styles["testimonial-content"]}>
                <h3>
                  What Our Clients <br /> Says
                </h3>
                <p>
                  Hear What Our Clients Say About Zenkify. Real Stories of
                  Healing and Transformation.Read Their Experiences and
                  Feedback. Join Our Community of Happy Clients and Explore the
                  Power of Energy Healing.
                </p>
              </div>
            </Col>
            <Col lg={7}>
              <OwlCarousel
                className={styles["testimonial-slider"] + " " + "owl-theme"}
                loop={false}
                margin={10}
                nav={false}
                dots={true}
                autoplay={true}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 3,
                  },
                  1000: {
                    items: 2,
                  },
                }}
              >
                <div className="item">
                  <div className={styles["testimonial-card"]}>
                    <span>
                      <i className="fa-solid fa-quote-right"></i>
                    </span>
                    <div className={styles["testimonial-header"]}>
                      {/* <div className={styles["user-image"]}>
                                <Image height={330} width={310} src= "/images/user-1.png"  alt="Jina Nilson" />
                              </div> */}
                      <div className={styles["user-name"]}>
                        <h4>Kayo Kai (India)</h4>
                        {/* <h6>Reiki Expert</h6> */}
                      </div>
                    </div>
                    <p>
                      I really loved the way she shared the remedies and things
                      which needs to be taken care in regards to my personal &
                      professional life. Enjoyed the session a lot. Thanks once
                      again.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className={styles["testimonial-card"]}>
                    <span>
                      <i className="fa-solid fa-quote-right"></i>
                    </span>
                    <div className={styles["testimonial-header"]}>
                      {/* <div className={styles["user-image"]}>
                                <Image height={330} width={310} src= "/images/user-1.png"  alt="Jina Nilson" />
                              </div> */}
                      <div className={styles["user-name"]}>
                        <h4>Kanika Jindal (India)</h4>
                        {/* <h6>Reiki Expert</h6> */}
                      </div>
                    </div>
                    <p>
                      It was an excellent experience with Shweta ji. Her calm
                      and soothing presence and her healing, really helped me
                      re-align my energies to my purpose.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className={styles["testimonial-card"]}>
                    <span>
                      <i className="fa-solid fa-quote-right"></i>
                    </span>
                    <div className={styles["testimonial-header"]}>
                      {/* <div className={styles["user-image"]}>
                                <Image height={330} width={310} src= "/images/user-1.png"  alt="Jina Nilson" />
                              </div> */}
                      <div className={styles["user-name"]}>
                        <h4>Katie Russell (USA)</h4>
                        {/* <h6>Reiki Expert</h6> */}
                      </div>
                    </div>
                    <p>
                      Her energy work was absolutely amazing! Filled up my cup
                      and gave me great practical tips to move forward with my
                      life. Will be back :)
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className={styles["testimonial-card"]}>
                    <span>
                      <i className="fa-solid fa-quote-right"></i>
                    </span>
                    <div className={styles["testimonial-header"]}>
                      {/* <div className={styles["user-image"]}>
                                <Image height={330} width={310} src= "/images/user-1.png"  alt="Jina Nilson" />
                              </div> */}
                      <div className={styles["user-name"]}>
                        <h4>Pragya Varma (Homemaker - USA)</h4>
                        {/* <h6>Reiki Expert</h6> */}
                      </div>
                    </div>
                    <p>
                      Finally, I got a chance to thank you. I have never met God
                      but I surely know that he has sent an angel for me. Thank
                      you, Angel, for your guiding light. You always go the
                      extra mile help everyone. ❤️
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className={styles["testimonial-card"]}>
                    <span>
                      <i className="fa-solid fa-quote-right"></i>
                    </span>
                    <div className={styles["testimonial-header"]}>
                      {/* <div className={styles["user-image"]}>
                                <Image height={330} width={310} src= "/images/user-1.png"  alt="Jina Nilson" />
                              </div> */}
                      <div className={styles["user-name"]}>
                        <h4>Aanchal Mehra (India)</h4>
                        {/* <h6>Reiki Expert</h6> */}
                      </div>
                    </div>
                    <p>
                      Shweta was great at explaining situations giving such
                      beautiful examples that once you are back home her
                      calmness and positive energy stays with you, I felt really
                      nice connecting with her, only one sitting with her
                      changes my outlook for so many things which were affecting
                      me mentally, thanks again Shweta 🙏
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Testimonial;
